// Types
import type {
  ManageBookingOriginalOrderItem,
  ManageBookingOriginalState,
  ManageBookingParsedOrderItem,
  ManageBookingParsedState,
  OriginalPiiData,
  OriginalSearchCriteria,
  PiiData,
} from '@white-label-types/parking-booking';
import {
  convertTimeObjToString,
  convertTimeStringToObj,
} from '@white-label-helper/time-helpers';

export const parseCustomerAgeGroups = (
  searchCriteria: OriginalSearchCriteria
) => {
  if (searchCriteria?.lounges?.groups) {
    const groups = searchCriteria.lounges.groups as { [k: string]: number };
    return Object.keys(groups).map((key) => {
      const capitalizedKey = key.charAt(0).toUpperCase() + key.slice(1);
      return `${groups[key]} x ${capitalizedKey}`;
    });
  } else {
    return [];
  }
};

const parseEntryExitInfo = ({
  started_at,
  closed_at,
  search_criteria,
}: {
  started_at: string;
  closed_at: string;
  search_criteria: OriginalSearchCriteria;
}) => {
  const parsedStartedAt = convertTimeStringToObj(started_at);
  const parsedClosedAt = convertTimeStringToObj(closed_at);
  return {
    entryDate: parsedStartedAt.date,
    entryTime: parsedStartedAt.time,
    exitDate: parsedClosedAt.date,
    exitTime: parsedClosedAt.time,
    stringEntryTime: convertTimeObjToString(parsedStartedAt),
    stringExitTime: convertTimeObjToString(parsedClosedAt),
    terminal:
      (search_criteria?.parking ?? search_criteria?.lounges)?.terminal ?? null,
  };
};

export function parseManageBookingState(
  state: ManageBookingOriginalState
): ManageBookingParsedState {
  const {
    created_at,
    order_reference,
    status,
    booking_fee,
    language,
    payable,
    id: orderId,
  } = state;

  const orderTotals = state.totals;

  const {
    id,
    discount,
    inventory_item,
    inventory_item_details,
    inventory_option,
    product,
    user,
    pii_data_token,
    totals,
    taxes,
    fees,
    cancellation_protection,
    entry_methods,
    original_order_item_id,
    cancellation_info,
    total_refunded,
    amendment_fee,
    search_criteria,
    product_code,
  } = state.items[0];

  const { started_at, closed_at, poi } = product;

  const showLicensePlate = typeof user.license_plate === 'string';

  const is_cancellable = (() => {
    if (state.is_cancellable !== undefined) {
      return state.is_cancellable;
    } else if ('cancellable_items' in state) {
      return state.items.length === state.cancellable_items?.length;
    }
    return state.items[0].is_cancellable;
  })();

  const is_amendable =
    'amendable_items' in state
      ? state.items.length === state.amendable_items?.length
      : state.items[0].is_amendable;

  let inventoryItem = inventory_item;
  if (inventory_item_details !== null) {
    inventoryItem = Object.assign(inventory_item, inventory_item_details);
  }

  return {
    showLicensePlate,
    id,
    discount,
    piiToken: pii_data_token,
    orderTotals,
    entryExitInfo: parseEntryExitInfo({
      started_at: inventory_item_details?.entry_date ?? started_at.datetime,
      closed_at: inventory_item_details?.exit_date ?? closed_at.datetime,
      search_criteria,
    }),
    originalOrderItemId: original_order_item_id,
    status: status.toUpperCase(),
    isCancellable: is_cancellable,
    isAmendable: is_amendable,
    paymentDate: created_at,
    orderId: order_reference,
    orderNumberId: orderId,
    orderItemReference: state.items[0].order_reference,
    locationInfo: poi,
    user,
    groups: parseCustomerAgeGroups(search_criteria),
    cancellationPolicies: inventory_option.cancellation_policies,
    amendmentPolicies: inventory_option.amendment_policies,
    parkingName: inventory_item.product_name,
    inventoryItem,
    entry_methods,
    cancellationProtection: cancellation_protection,
    cancellationInfo: cancellation_info,
    totals,
    taxes,
    fees,
    totalRefunded: total_refunded,
    amendmentFee: amendment_fee,
    items: state.items.map((item: ManageBookingOriginalOrderItem) => {
      if (item.inventory_item_details !== null) {
        Object.assign(item.inventory_item, item.inventory_item_details);
      }
      return parseManageBookingOrderItem(item);
    }),
    bookingFee: booking_fee,
    productCode: product_code,
    language,
    searchCriteria: search_criteria,
    payable,
  };
}

/**
 * Parse an order item to include formatted entry and exit info.
 */
export const parseManageBookingOrderItem = (
  item: ManageBookingOriginalOrderItem
): ManageBookingParsedOrderItem => {
  const { product } = item;
  const entryExitInfo = parseEntryExitInfo({
    started_at: product.started_at.datetime,
    closed_at: product.closed_at.datetime,
    search_criteria: item.search_criteria,
  });
  return {
    ...item,
    entryExitInfo,
  };
};

export const parseManageBookingPiiData = (
  data: OriginalPiiData
): Omit<PiiData, 'bookingItemId'> => {
  const { pii_data } = data;

  return {
    guests: pii_data.guests,
    status: data.status,
    vault: data.vault,
    token: data.token,
  };
};
