
import { defineComponent, PropType } from 'vue';
// Types
import type { EntryMethod } from '@white-label-types/parking-booking';
// Constants
import { BARRIER_ACCESS_TYPES, NAMED_ROUTES } from '@white-label-configuration/constants';
import { RouterLink } from 'vue-router'
import BTicketGuests from '../b-ticket-guests/b-ticket-guests.vue';
import BTicketPrivateLounge from '../b-ticket-private-lounge-details/b-ticket-private-lounge-details.vue';
import { getDomainUrl, DOMAINS_NAMES } from '@white-label-helper/switch-app';
export default defineComponent({
  name: 'BTicketAccessInfoGuests',
  components: {
    BTicketGuests,
    BTicketPrivateLounge,
    RouterLink,
  },
  props: {
    accessInfo: {
      type: [Object, String] as PropType<EntryMethod['entryMethod'] | string>,
      required: true,
    },
    accessInfoFromEntranceInfo: {
      type: Boolean,
      default: false,
    },
    subItems: {
      type: Array,
      default() {
        return [];
      },
    },
    showProviderLogo: {
      type: Boolean,
      default: false,
    },
    product: {
      type: Object,
      required: false,
      default: ()=> {},
    },
  },
  data(){
    return {
      ticketAccessInfo: {}
      }
  },
  computed: {
    downloadToken(): string {
       return  this.product?.details?.download_token;
    },
    ticketDowloadLink(): object {
      return {'path': NAMED_ROUTES.downloads.ticket, 'query': {token: this.downloadToken}, 'host': getDomainUrl(DOMAINS_NAMES.ACCOUNT)}
    },

    formattedAccessType(): string {
      return this.accessInfo?.type?.toLowerCase();
    },
    displayImg(): boolean {
      return this.accessInfo?.type === BARRIER_ACCESS_TYPES.QRCODE;
    },
    isPrivateLounge() {
      return (
        (this.product &&
          (this.product?.productOption === 'lounge_private' ||
            this.product?.inventory_item?.product_option.code ===
              'lounge_private')) ||
        this.product?.inventoryItem?.product_option.code === 'lounge_private'
      );
    },
  },
  created(){
    this.ticketAccessInfo = this.accessInfo;
    if(typeof this.accessInfo === 'string'){
      this.ticketAccessInfo = {value : this.accessInfo}
    }
},
});
