import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _e762ecc4 = () => interopDefault(import('../src/pages/cookie-policy.vue' /* webpackChunkName: "pages/cookie-policy" */))
const _3911bab6 = () => interopDefault(import('../src/pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _553617a1 = () => interopDefault(import('../src/pages/manage-booking/index.vue' /* webpackChunkName: "pages/manage-booking/index" */))
const _31236878 = () => interopDefault(import('../src/pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _ee73c64a = () => interopDefault(import('../src/pages/receipt.vue' /* webpackChunkName: "pages/receipt" */))
const _6455fa56 = () => interopDefault(import('../src/pages/search.vue' /* webpackChunkName: "pages/search" */))
const _31db7524 = () => interopDefault(import('../src/pages/search-booking/index.vue' /* webpackChunkName: "pages/search-booking/index" */))
const _667d79fa = () => interopDefault(import('../src/pages/select-extras/index.vue' /* webpackChunkName: "pages/select-extras/index" */))
const _cc0a403e = () => interopDefault(import('../src/pages/terms-and-conditions.vue' /* webpackChunkName: "pages/terms-and-conditions" */))
const _2e7db1b3 = () => interopDefault(import('../src/pages/unsupported-browser.vue' /* webpackChunkName: "pages/unsupported-browser" */))
const _096e5804 = () => interopDefault(import('../src/pages/manage-booking/cancel/index.vue' /* webpackChunkName: "pages/manage-booking/cancel/index" */))
const _6fd8effb = () => interopDefault(import('../src/pages/manage-booking/modify.vue' /* webpackChunkName: "pages/manage-booking/modify" */))
const _4825cc47 = () => interopDefault(import('../src/pages/manage-booking/receipt.vue' /* webpackChunkName: "pages/manage-booking/receipt" */))
const _71f1117f = () => interopDefault(import('../src/pages/manage-booking/update-details.vue' /* webpackChunkName: "pages/manage-booking/update-details" */))
const _43e6034c = () => interopDefault(import('../src/pages/select-extras/fasttrack/index.vue' /* webpackChunkName: "pages/select-extras/fasttrack/index" */))
const _0f37e5e4 = () => interopDefault(import('../src/pages/select-extras/lounges/index.vue' /* webpackChunkName: "pages/select-extras/lounges/index" */))
const _970a3538 = () => interopDefault(import('../src/pages/manage-booking/cancel/receipt.vue' /* webpackChunkName: "pages/manage-booking/cancel/receipt" */))
const _01197d35 = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/cookie-policy",
    component: _e762ecc4,
    name: "cookie-policy"
  }, {
    path: "/maintenance",
    component: _3911bab6,
    name: "maintenance"
  }, {
    path: "/manage-booking",
    component: _553617a1,
    name: "manage-booking"
  }, {
    path: "/privacy-policy",
    component: _31236878,
    name: "privacy-policy"
  }, {
    path: "/receipt",
    component: _ee73c64a,
    name: "receipt"
  }, {
    path: "/search",
    component: _6455fa56,
    name: "search"
  }, {
    path: "/search-booking",
    component: _31db7524,
    name: "search-booking"
  }, {
    path: "/select-extras",
    component: _667d79fa,
    name: "select-extras"
  }, {
    path: "/terms-and-conditions",
    component: _cc0a403e,
    name: "terms-and-conditions"
  }, {
    path: "/unsupported-browser",
    component: _2e7db1b3,
    name: "unsupported-browser"
  }, {
    path: "/manage-booking/cancel",
    component: _096e5804,
    name: "manage-booking-cancel"
  }, {
    path: "/manage-booking/modify",
    component: _6fd8effb,
    name: "manage-booking-modify"
  }, {
    path: "/manage-booking/receipt",
    component: _4825cc47,
    name: "manage-booking-receipt"
  }, {
    path: "/manage-booking/update-details",
    component: _71f1117f,
    name: "manage-booking-update-details"
  }, {
    path: "/select-extras/fasttrack",
    component: _43e6034c,
    name: "select-extras-fasttrack"
  }, {
    path: "/select-extras/lounges",
    component: _0f37e5e4,
    name: "select-extras-lounges"
  }, {
    path: "/manage-booking/cancel/receipt",
    component: _970a3538,
    name: "manage-booking-cancel-receipt"
  }, {
    path: "/",
    component: _01197d35,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
