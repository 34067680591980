import {
  parseISO,
  add,
  differenceInDays,
  startOfToday,
  format,
  DATE_TIME_FORMATS,
} from '@white-label-helper/date-utilities';
import { getAppVariable } from '@white-label-helper/get-app-variable';
import type { LoungesSearchCriteria } from '@white-label-types/product-types';

type GaDataLayer = {
  /** Whether the user is logged in or not */
  loginStatus: boolean;
  /** The user's ID (We don't have this atm) */
  userId: string;
  /** The airport name */
  airportName: string;
  /** The airport code */
  airportCode: string;
  /** The airport terminal number or "dontKnow" */
  airportTerminalId: string;
  /** What the service is (e.g. "parking") */
  serviceType: string;
  /** How far in advance the booking was made */
  daysAheadOfBooking: number;
  /** The date of departure */
  parkingDepartureDate: string;
  /** The time of departure */
  parkingDepartureTime: string;
  /** The date of return */
  parkingReturnDate: string;
  /** The time of return */
  parkingReturnTime: string;
  /** The duration between departure and return */
  stayDuration: number;
  /** The order reference */
  orderReference: string;
  /** The coupon or voucher code */
  couponVoucher: string;
  /** The name of the partner */
  partnerName: string;
  /** The language of the site */
  siteLanguage: string;
  /** The parking name */
  carParkName: string;
  /** GA event name */
  event?: string;
};

/** The data object to send to GA */
const gaLayer: GaDataLayer = {
  loginStatus: false,
  userId: '',
  airportName: '',
  airportCode: '',
  airportTerminalId: '',
  serviceType: 'parking',
  daysAheadOfBooking: 0,
  parkingDepartureDate: '',
  parkingDepartureTime: '',
  parkingReturnDate: '',
  parkingReturnTime: '',
  stayDuration: 0,
  orderReference: '',
  couponVoucher: '',
  partnerName: '',
  siteLanguage: 'en-US',
  carParkName: '',
};

type Options = {
  loginStatus?: boolean;
  airportName?: string;
  airportCode?: string;
  airportTerminalId?: string;
  parkingDepartureDate?: string;
  parkingDepartureTime?: string;
  parkingReturnDate?: string;
  parkingReturnTime?: string;
  orderReference?: string;
  couponVoucher?: string;
  partnerName?: string;
  siteLanguage?: string;
  carParkName?: string;
  event?: string;
};

/**
 * Set and fire the GA dataLayer
 * @param options - The options to update the data with
 * @returns The dataLayer object
 */

export const gaDataLayer = (options: Options) => {
  const {
    loginStatus = gaLayer.loginStatus,
    airportName = gaLayer.airportName,
    airportCode = gaLayer.airportCode,
    airportTerminalId = gaLayer.airportTerminalId,
    parkingDepartureDate = gaLayer.parkingDepartureDate,
    parkingDepartureTime = gaLayer.parkingDepartureTime,
    parkingReturnDate = gaLayer.parkingReturnDate,
    parkingReturnTime = gaLayer.parkingReturnTime,
    orderReference = gaLayer.orderReference,
    couponVoucher = gaLayer.couponVoucher,
    partnerName = gaLayer.partnerName,
    siteLanguage = gaLayer.siteLanguage,
    carParkName = gaLayer.carParkName,
  } = options;

  if (parkingDepartureDate) {
    gaLayer.daysAheadOfBooking = differenceInDays(
      parseISO(parkingDepartureDate),
      startOfToday()
    );
  }

  if (parkingDepartureDate && parkingReturnDate) {
    const arrival = parseISO(parkingReturnDate);
    const departure = parseISO(parkingDepartureDate);
    const addOneDay = add(arrival, { days: 1 });
    // Adding 1 day to arrival to make sure it's included in the calculation
    const days = (+addOneDay - +departure) / 864e5; // Days in milliseconds
    // The min value is 1
    gaLayer.stayDuration = Math.round(days) || 1;
  }

  if (options.event) {
    gaLayer.event = options.event;
  }

  gaLayer.loginStatus = loginStatus;
  gaLayer.airportCode = airportCode;
  gaLayer.airportTerminalId = airportTerminalId;
  gaLayer.parkingDepartureDate = parkingDepartureDate;
  gaLayer.parkingDepartureTime = parkingDepartureTime;
  gaLayer.parkingReturnDate = parkingReturnDate;
  gaLayer.parkingReturnTime = parkingReturnTime;
  gaLayer.orderReference = orderReference;
  gaLayer.couponVoucher = couponVoucher;
  gaLayer.partnerName = partnerName;
  gaLayer.siteLanguage = siteLanguage;
  gaLayer.carParkName = carParkName;
  gaLayer.airportName = airportName;

  return gaLayer;
};

export function gaDataLayerLounges(
  formData: LoungesSearchCriteria['lounges'],
  extend = {}
) {
  const entryDate = formData.date1 || '';
  const groups = formData.groups || {};
  return {
    airportCode: getAppVariable('poi.code'),
    partnerName: getAppVariable('partner_name'),
    terminalId: `${formData?.terminal || 'dontKnow'}`,
    loungeName: '',
    serviceType: 'lounges',
    userType: '',
    loginStatus: false,
    siteLanguage: getAppVariable('default_language'),
    userId: '',
    daysAheadOfBooking: differenceInDays(parseISO(entryDate), startOfToday()),
    entryDate: entryDate
      ? format(parseISO(entryDate), DATE_TIME_FORMATS.year_month_day)
      : '',
    entryTime: formData?.time1 || '',
    numberOfGuest: Object.values(groups).reduce((acc, num) => acc + num, 0),
    adult: (groups['adult'] || 0) + (groups['senior'] || 0),
    children: (groups['child'] || 0) + (groups['infant'] || 0),
    ...extend,
  };
}
