
// Packages
import { defineComponent, PropType } from 'vue';
import VueI18n from 'vue-i18n';

// Types
import type {
  CartItem,
  Total,
  Receipt,
} from '@white-label-types/parking-checkout';
import type { TaxFee, BookingFee } from '@white-label-types/parking-booking';

// Helpers
import { formatPrice } from '@white-label-helper/helper-payment';
import { getAppVariable } from '@white-label-helper/get-app-variable';

// Components
import SummaryPreloader from '../summary-preloader/summary-preloader.vue';
import IconMessage from '../icon-message/icon-message.vue';
import SummarySubtitle from '../summary-subtitle/summary-subtitle.vue';
import ProductSummaryCard from '../product-summary-card/product-summary-card.vue';

// Stores
import { readDepositOnly } from '@white-label-store/deposits'

type SummaryElementItems = CartItem[] | Receipt['items'];
type SubItems = {
  title: string;
  value: string | Intl.NumberFormat;
  isDiscount?: boolean;
};

export default defineComponent({
  name: 'PaymentSummary',

  components: {
    IconAlert: () => import('@white-label-icon/icon-alert'),
    IconCheck: () => import('@white-label-icon/icon-check'),
    IconArrow: () => import('@white-label-icon/icon-arrow'),
    IconMessage,
    SummaryPreloader,
    SummarySubtitle,
    ProductSummaryCard
  },
  props: {
    amountToPay: {
      type: [Number, null] as PropType<number | null>,
      default: null,
    },
    contentLoading: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array as PropType<SummaryElementItems>,
      default: () => [] as SummaryElementItems,
    },
    originalOrder: {
      type: Object as PropType<SummaryElementItems>,
      required: false,
      default: () => ({}),
    },
    hasPaid: {
      type: Boolean,
      default: false,
    },
    isModifyFlowReceipt: {
      type: Boolean,
      default: false,
    },
    isRefund: {
      type: Boolean,
      default: false,
    },
    orderTotals: {
      type: Object as PropType<Total>,
      default: () => ({}),
    },
    priceDifference: {
      type: String,
      default: '',
    },
    amendmentFee: {
      type: String,
      default: '',
    },
    isCancelling: {
      type: Boolean,
      default: false,
    },
    cancellationFee: {
      type: String,
      default: '',
    },
    refundDoesNotExist: {
      type: Boolean,
      default: false,
    },
    refundProcessing: {
      type: Boolean,
      default: false,
    },
    valueAfterRefundWithoutCancellation: {
      type: String,
      default: '',
    },
    originalOrderHasCancellationProtection: {
      type: Boolean,
      default: false,
    },
    isSticky: {
      type: Boolean,
      default: false,
    },
    bookingFee: {
      type: Object as PropType<BookingFee>,
      default: () => ({}),
    },
  },

  data() {
    return {
      isExpanded: false,
      isCancellationProtectionExpanded: false,
      showDiscountBreakdown: false,
      showTaxesBreakdown: false,
      showBasket: false,
    };
  },
  computed: {
    sortedItems(): SummaryElementItems {
      return this.items.slice().sort((item) => item.product_code === 'lounges' ? 1 : -1);
    },

    isModifyCheckout(): boolean {
      return !!this.originalItemTotalPrice;
    },

    displayTravelExtrasSummary(){
      return this['$launchDarkly'].variation('ECOM-1770-Travel-Extras-Payment-Summary');
    },

    displaySummaryBottomSectionCancelled(): boolean | string {
      return this.isCancelling || this.cancellationFee;
    },

    originalOrderProps(): { [key: string]: any } {
      const item = this.originalOrder;
      if (!item.inventory_option) {
        return {};
      }
      const sub_options = item.inventory_option.sub_options || [];
      const labels: { [key: string]: string } = {
        senior: 'Senior',
        adult: 'Adult',
        child: 'Child',
        infant: 'Infant',
      };
      const subItems: SubItems[] = [];
      const inventoryMap: { [key: string]: any } = {};
      sub_options.forEach((listItem) => {
        if (listItem?.details?.name) {
          inventoryMap[listItem.details.name] = listItem;
        }
      });
      ['senior', 'adult', 'child', 'infant'].forEach((key) => {
        const item = inventoryMap[key];
        if (!item) {
          return;
        }
        // Added label[key] until cart data response has added name_formatted
        const name = item.details?.name_formatted
          ? item.details.name
          : labels[key];
        const totals =
          'original_amounts' in item
            ? item.original_amounts.totals
            : item.totals;
        const quantity = item.quantity || totals.quantity;
        if (quantity) {
          const label = `${quantity} x ${name}`;
          subItems.push({
            title: label,
            value: formatPrice(totals.subtotal),
          });
          if (item.discount) {
            subItems.push({
              isDiscount: true,
              title: item.discount.description,
              value: formatPrice(item.discount.amount),
            });
          }
        }
      });

      const displayName =
        item?.inventory_item && 'display_name' in item.inventory_item
          ? item.inventory_item.display_name
          : '';

      const orderName = displayName || item?.inventory_item?.name || '';

      return {
        taxes: item.taxes,
        fees: item.fees,
        orderName,
        subItems,
      };
    },

    cancellationProtection(): { [key: string]: any } {
      const cancellationProtectionProduct = {
        is_protected: false,
        product_code: 'cancellation_protection',
        inventory_item: {
          name: this.$t('shared.summaryLounges.cancellationProtection'),
        },
        totals: {
          total: 0
        },
      };

      this.items.forEach((item) => {
        if (item.is_protected || item.cancellation_policies?.is_protected) {
          const cancellationProtectionPrice = item.cancellation_protection?.totals?.total ?? 0;

          cancellationProtectionProduct.is_protected = true;
          cancellationProtectionProduct.totals.total = cancellationProtectionProduct.totals.total + cancellationProtectionPrice;
        }
      });

      return cancellationProtectionProduct;
    },

    isAmending(): boolean {
      const item = this.items[0];
      return item && 'meta' in item && !!item.meta?.original_order_item_id;
    },
    originalItemTotalPrice(): string {
      const item = this.items[0];
      if ('meta' in item) {
        return `${item?.meta?.original_order_item_total_price || ''}`;
      }
      return '';
    },
    totalLabel(): VueI18n.TranslateResult {
      if (this.hasDeposit && !(this.isReceiptPage || this.isManageBookingPages)) {
        return this.$t('shared.payNow');
      }

      if (this.isModifyFlowReceipt) {
        if (this.amountToPay !== null && this.amountToPay < 0) {
          return this.$t('shared.refundAmount');
        }
        if (this.amountToPay !== null && this.amountToPay > 0) {
          return this.$t('shared.amountPaid');
        }
        return this.$t('shared.priceDifference');
      }
      if (this.amountToPay !== null && this.amountToPay > 0) {
        return this.$t('shared.total');
      }
      if (this.isRefund) {
        if (this.amountToPay === 0) {
          return this.$t('shared.total');
        }

        if (this.hasDeposit) {
          return this.$t('shared.amountRefund');
        }
        return this.$t('shared.refundAmount');
      }
      if (this.hasPaid) {
        return this.$t('shared.totalPaid');
      }
      return this.$t('shared.total');
    },
    orderTotalFormatted(): string | Intl.NumberFormat {
      return typeof this.orderTotals?.total === 'number'
        ? formatPrice(this.orderTotals.total)
        : '';
    },
    totalValue(): string | Intl.NumberFormat {
      if (this.hasDeposit) {
        return this.hasDeposit.payNow;
      }

      if (!this.isModifyFlowReceipt) {
        if (this.valueAfterRefundWithoutCancellation) {
          return this.valueAfterRefundWithoutCancellation;
        }

        return this.isRefund || this.isModifyCheckout
          ? this.priceDifference
          : this.orderTotalFormatted;
      }

      return this.priceDifference;
    },
    productsTotal(): string {
      let productCount = this.items.length;

      if (this.cancellationProtection.is_protected) {
        productCount = productCount + 1
      }

      return `(${productCount} ${this.$t('shared.products')})`
    },
    infoMessage(): { text: string; slot0?: string } | string {
      if (this.amountToPay !== null && this.amountToPay > 0) {
        return {
          text: 'shared.summaryLounges.originalPriceIsLower',
          slot0: 'shared.summaryLounges.originalPriceIsLowerSlot0',
        };
      }
      if (this.amountToPay !== null && this.amountToPay < 0) {
        return {
          text: 'shared.summaryLounges.originalPriceIsHigher',
          slot0: 'shared.summaryLounges.originalPriceIsHigherSlot0',
        };
      }
      if (this.amountToPay === 0) {
        return {
          text: 'shared.summaryLounges.sameAsOriginalPrice',
          slot0: 'shared.summaryLounges.sameAsOriginalPriceSlot0',
        };
      }
      if (this.refundDoesNotExist) {
        return {
          text: 'shared.summaryLounges.refundDoesNotExist',
        };
      }
      if (this.refundProcessing) {
        return {
          text: 'shared.summaryLounges.refundProcessing',
        };
      }
      return '';
    },

    /**
     * Checks if there is a deposit but checking for the existence of a null
     * value or not
     *
     * @returns Returns true if there is a deposit, otherwise false.
     */
    hasDeposit(): false | { payNow: string, payOnArrival: string } {
      const depositOnly = readDepositOnly(this.$store);
      if (depositOnly === null) {
        return false;
      }

      return depositOnly;
    },

    /**
     * Returns the summary text based on state of product.
     * If a deposit has been paid, it returns 'Deposit paid',
     * otherwise it returns the translated value of 'shared.summaryParkings.totalPaid'.
     *
     * @return {VueI18n.TranslateResult} The summary text.
     */
    summaryText(): VueI18n.TranslateResult {
      if (this.hasDeposit) {
        return this.$t('shared.depositPaid');
      }

      return this.$t('shared.summaryLounges.totalPaid');
    },

    /**
     * Checks if the current page is a receipt page.
     *
     * @returns Returns true if the current page is a receipt page, false otherwise.
     */
    isReceiptPage(): boolean {
      // @ts-ignore
      return this.$route && this.$route.name.includes('receipt');
    },

    /**
     * Determines if the current route is a manage booking page.
     *
     * @returns True if the current route is a manage booking page, otherwise false.
     */
    isManageBookingPages(): boolean {
      // @ts-ignore
      return this.$route && this.$route.name.includes('manage-booking');
    },

    displayDiscountSection(): boolean {
      return this.orderTotals.discount !== 0
        && this['$launchDarkly'].variation('PT-1566-DISPLAY-NEW-PAYMENT-SUMMARIES');
    },

    combinedTaxes(): TaxFee[] {
      // Create a map to store unique items by their name
      const itemMap = new Map();

      this.items.forEach((item) => {
        item.taxes.forEach((tax) => {
          // If the item name is already in the map, sum the amounts
          if (itemMap.has(tax.name)) {
            itemMap.get(tax.name).amount += tax.amount;
          } else {
            // Otherwise, add the item to the map
            itemMap.set(tax.name, { ...tax });
          }
        })
      });

      // Convert the map values back to an array
      return Array.from(itemMap.values());
    },

    taxesTotal(): number {
      return this.items.reduce((previousItem, currentItem) => previousItem + currentItem.totals.taxes ,0);
    },

    displayTaxesSection(): boolean {
      return this.combinedTaxes.length !== 0
        && !getAppVariable('is_tax_inclusive')
        && this['$launchDarkly'].variation('PT-1566-DISPLAY-NEW-PAYMENT-SUMMARIES');
    },

    bookingFeeAmountFormatted(): string | Intl.NumberFormat {
      return formatPrice(this.bookingFee.amount);
    },
  },
  methods: {
    formatPrice,
    onClickCancellationProtectionHandler() {
      this.isCancellationProtectionExpanded =
        !this.isCancellationProtectionExpanded;
    },
  },
});
